@font-face {
    font-family: 'IransansWeb';
    src: local('./IRANSansWeb(FaNum).woff2'), url('./IRANSansWeb(FaNum).woff2') format('woff');
    /* src: './IRANSansWeb(FaNum).woff2'format('woff2'); */
}

body{
    overflow-y: scroll;
    direction: rtl;
    margin: 0;
    background-color: rgb(250 250 250);
    font-family: IransansWeb;
}

/* width */
body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: #f1f1f1;
    
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: #aeaeae;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: #6d6d6d;
}
button ,textarea ,input , select , option , p , span , h1, h2 , h3 , h4 , a , div ,img , image{
    font-family: IransansWeb;
}
button{
    cursor: pointer;
}
a{
    text-decoration: none;
}

/* menu sidebar style */

.menu_sidebar_app{
     background: rgb(241 241 241);
     width: 20%;
     height: 100%;
     position: fixed;
     z-index: 10;
     box-sizing: border-box;
     overflow: auto;
     padding-top: 25px;
}
.menu_sidebar_app .btn_page{
    background: none;
    border: none;
    color: black;
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    text-align: right;
    padding-right: 30px;
    font-weight: 700;
}
.menu_sidebar_app .logout {
    background-color: #cd405e;
    width: 130px;
    color: white;
    border-radius: 29px;
    margin-top: 14px;
    margin-right: 20px;
}
.menu_sidebar_app .logout:hover{
  background-color: red;
}
.menu_sidebar_app .logout span{
    margin-right: 20px;
}
.menu_sidebar_app .logout img{
    width: 26px;
    position: absolute;
    margin-top: -2px;
    margin-right: -11px;
}
/* width */
.menu_sidebar_app::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.menu_sidebar_app::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.menu_sidebar_app::-webkit-scrollbar-thumb {
    background: #ba8787;
}

/* Handle on hover */
.menu_sidebar_app::-webkit-scrollbar-thumb:hover {
    background: #ae4e4e;
}

/* main app style */

.main_app{
    width: 80%;
    float: left;
    box-sizing: border-box;
}

/* spinner loading style */

/* load data spinner */


.load_container {
    width: 100%;
    height: 100%;
    background-color: #00000064;
    position: fixed;
    margin: 0;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 12;
}

.inner_load_data {
    width: 100%;
    position: fixed;
    display: block;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

/* scroll spinner */

.parent_load_scroll .loadingio-spinner-ellipsis-3k0o1osq94t {
    background-color: white;
}


@keyframes ldio-fg094ugvyz {
    0% {
        transform: translate(6px, 40px) scale(0);
    }

    25% {
        transform: translate(6px, 40px) scale(0);
    }

    50% {
        transform: translate(6px, 40px) scale(1);
    }

    75% {
        transform: translate(40px, 40px) scale(1);
    }

    100% {
        transform: translate(74px, 40px) scale(1);
    }
}

@keyframes ldio-fg094ugvyz-r {
    0% {
        transform: translate(74px, 40px) scale(1);
    }

    100% {
        transform: translate(74px, 40px) scale(0);
    }
}

@keyframes ldio-fg094ugvyz-c {
    0% {
        background: white
    }

    25% {
        background: white
    }

    50% {
        background: white
    }

    75% {
        background: white
    }

    100% {
        background: white
    }
}

.ldio-fg094ugvyz div {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: translate(40px, 40px) scale(1);
    background: white;
    animation: ldio-fg094ugvyz 2.272727272727273s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-fg094ugvyz div:nth-child(1) {
    background: white;
    transform: translate(74px, 40px) scale(1);
    animation: ldio-fg094ugvyz-r 0.5681818181818182s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-fg094ugvyz-c 2.272727272727273s infinite step-start;
}

.ldio-fg094ugvyz div:nth-child(2) {
    animation-delay: -0.5681818181818182s;
    background: white;
}

.ldio-fg094ugvyz div:nth-child(3) {
    animation-delay: -1.1363636363636365s;
    background: white;
}

.ldio-fg094ugvyz div:nth-child(4) {
    animation-delay: -1.7045454545454546s;
    background: white;
}

.ldio-fg094ugvyz div:nth-child(5) {
    animation-delay: -2.272727272727273s;
    background: white;
}

.loadingio-spinner-ellipsis-3k0o1osq94t {
    width: 30px;
    height: 40px;
    display: inline-block;
    background: none;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 19px;
    border-radius: 100px;
}

.ldio-fg094ugvyz {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.64);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-fg094ugvyz div {
    box-sizing: content-box;
}
/* error style */
.parent_error_connection {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 14;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #00000062;
}

.parent_error_connection .error_inner {
    background: white;
    width: 400px;
    height: auto;
    border-radius: 10px;
    box-sizing: border-box;
    padding-top: 12px;
    padding-bottom: 18px;
    padding-right: 28px;
    padding-left: 20px;
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.parent_error_connection .error_inner p {

    font-size: 15px;
}

.parent_error_connection .error_inner button {

    background-color: #595980;
    border: none;
    color: white;
    padding: 6px 20px 6px 20px;
    border-radius: 10px;
}
@media only screen and (max-width: 550px) {
    .parent_error_connection .error_inner {
        width: 95%;
    }
}
.parent_error_message{
    width: 80%;
    position: fixed;
    bottom: 10px;
    left: 0;
    z-index: 14;
}
.parent_error_message .error_message{
    background: #ce6969;
    color: white;
    font-size: 14px;
    width: 300px;
    text-align: center;
    border-radius: 2px;
    box-sizing: border-box;
    margin: 0 auto;
    padding-top: 6px;
    padding-bottom: 6px;
}
/* delete alert modal style */

.delete_modal {
    background-color: #0000006b;
    position: fixed;
    width: 100%;
    height: 100vh;
    display: block;
    z-index: 11;
    right: 0;
    top: 0;
}

.delete_modal .inner_delete_modal {
    background-color: white;
    z-index: 9;
    width: 500px;
    height: 200px;
    border-radius: 6px;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    position: relative;
}

.delete_modal .inner_delete_modal .delete_close_modal span {
    position: absolute;
    padding: 6px 26px;
    font-size: 25px;
    color: rgb(190, 190, 190);
    cursor: pointer;
    z-index: 1;
}

.delete_modal .inner_delete_modal .title {
    width: 100%;
    margin-top: 20px;
    position: absolute;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.delete_modal .inner_delete_modal .text {
    width: 100%;
    margin-top: 75px;
    position: absolute;
    text-align: center;
    font-size: 14px;

}

.delete_modal .inner_delete_modal .btns {
    width: 100%;
    display: block;
    margin-top: 140px;
    position: absolute;
    text-align: center;
}

.delete_modal .inner_delete_modal .cansel {
    display: inline-block;
    width: 50%;

}

.delete_modal .inner_delete_modal .ok {
    display: inline-block;
    width: 50%;

}

.delete_modal .inner_delete_modal .cansel div {
    width: 60%;
    background-color: #eaeaea;
    color: #000000;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 60px;
}

.delete_modal .inner_delete_modal .ok div {
    background-color: rgb(151, 74, 69);
    color: white;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 60px;
}

@media only screen and (max-width: 700px) {
    .delete_modal .inner_delete_modal {

        width: 96%;
    }
}

/* success alert style */

.parent_success_container .parent_success {

    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #0000007d;
    top: 0;
    right: 0;
    z-index: 12;
}

.parent_success_container .parent_success .modal {
    width: 500px;
    height: auto;
    background-color: white;
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 15px;
    padding: 6px;
    box-sizing: border-box;

}

.parent_success_container .parent_success .modal .title {

    display: block;
    background-color: #ffffff;
    text-align: center;
    font-size: 20px;
    box-sizing: border-box;
    padding-top: 0px;
    padding-bottom: 26px;
    color: #000000;
}

.parent_success_container .parent_success .modal .description {

    display: block;
    background-color: #ffffff;
    text-align: center;
    font-size: 17px;
    box-sizing: border-box;
    padding-top: 0px;
    padding-bottom: 26px;
    color: #000000;
}


.parent_success_container .parent_success .modal .parent_btn {

    background-color: rgb(255, 255, 255);
    padding-bottom: 15px;
}

.parent_success_container .parent_success .modal .parent_btn .btn {
    background-color: #536783;
    color: white;
    margin: 0 auto;
    width: 100px;
    display: block;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    font-size: 15px;
    cursor: pointer;
}

/* success modal icon animated */

.sa {
    width: 140px;
    padding: 26px;
    background-color: #fff;
    display: block;
    margin: 0 auto;
}

.sa-success {
    border-radius: 50%;
    border: 4px solid #a5dc86;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    background-color: #fff;
    width: 80px;
    position: relative;
    margin: 0 auto;
}

.sa-success:after,
.sa-success:before {
    background: #fff;
    content: '';
    height: 120px;
    position: absolute;
    transform: rotate(45deg);
    width: 60px;
}

.sa-success:before {
    border-radius: 40px 0 0 40px;
    width: 26px;
    height: 80px;
    top: -17px;
    left: 5px;
    transform-origin: 60px 60px;
    transform: rotate(-45deg);
}

.sa-success:after {
    border-radius: 0 120px 120px 0;
    left: 30px;
    top: -11px;
    transform-origin: 0 60px;
    transform: rotate(-45deg);
    animation: rotatePlaceholder 4.25s ease-in;
}

.sa-success-placeholder {
    border-radius: 50%;
    border: 4px solid rgba(165, 220, 134, 0.25);
    box-sizing: content-box;
    height: 80px;
    left: -4px;
    position: absolute;
    top: -4px;
    width: 80px;
    z-index: 2;
}

.sa-success-fix {
    background-color: #fff;
    height: 90px;
    left: 28px;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 5px;
    z-index: 1;
}

.sa-success-tip,
.sa-success-long {
    background-color: #a5dc86;
    border-radius: 2px;
    height: 5px;
    position: absolute;
    z-index: 2;
}

.sa-success-tip {
    left: 14px;
    top: 46px;
    transform: rotate(45deg);
    width: 25px;
    animation: animateSuccessTip 0.75s;
}

.sa-success-long {
    right: 8px;
    top: 38px;
    transform: rotate(-45deg);
    width: 47px;
    animation: animateSuccessLong 0.75s;
}

@keyframes animateSuccessTip {

    0%,
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessLong {

    0%,
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@keyframes rotatePlaceholder {

    0%,
    5% {
        transform: rotate(-45deg);
    }

    100%,
    12% {
        transform: rotate(-405deg);
    }
}

@media (max-width:700px) {

    .parent_success_container .parent_success .modal {
        width: 90% !important;
    }

    .parent_success_container .parent_success .modal .title {

        font-size: 16px !important;
        padding-top: 0px !important;
        padding-bottom: 13px !important;

    }

    .parent_success_container .parent_success .modal .description {

        font-size: 14px !important;
        padding-top: 0px !important;
        padding-bottom: 19px !important;
    }

    .parent_success_container .parent_success .modal .parent_btn .btn {

        border-radius: 83px !important;

    }

    .parent_success .modal .sa {

        padding-top: 10px !important;
    }


}

/* list page style */

.list_parent{
    width: 90%;
    margin:0 auto;
    padding-top: 100px;
    padding-bottom: 50px;
    box-sizing: border-box;
}
.header{
    width: 80%;
    height: 80px;
    background-color: rgb(250 250 250);
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
    z-index: 2;
    border-bottom: 1px solid #e9e9e9;
}
.header .inner_header{
    width: 90%;
    margin: 0 auto;
}
.header .inner_header .title{
    font-weight: 700;
    font-size: 16px;
}
.list_parent .header .search_parent{
    display: inline-block;
    margin-right: 50px;
}
.list_parent .header .search_parent img{
    width: 20px;
    position: absolute;
    margin-right: 13px;
    margin-top: 11px;
    opacity: 0.6;
}
.list_parent .search_parent input{
    width: 400px;
    background-color: unset;
    border: 1px solid rgb(204, 204, 204);
    height: 40px;
    border-radius: 70px;
    padding-left: 10px;
    padding-right: 43px;
    font-size: 13px;
}
.list_parent .header .title{
    display: inline-block;
    font-weight: 700;
    font-size: 15px;
}
.list_parent .header .create_parent{
    display: inline-block;
}
.list_parent .header .create_parent{
    float: left;
     margin-left: 40px;
}
.list_parent .header .create_parent .create{
    background-color: #e4f3eb;
    color: #376f44;
    border-radius: 22px;
    padding: 10px 18px 11px 18px;
    border: none;
    font-size: 13px;
    font-weight: 700;
}
.list{
    padding-top: 30px;
    box-sizing: border-box;
}
.list .item{
    display: inline-block;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 10px;
}
.list .item_admin {
    background-color: rgb(243 243 243);
    border: 4px solid #ffffff !important;
}

.list .item_deleted{
    background-color: #fff9f973 !important;
    border: 2px solid #ffd9d9 !important;
}
.list .undo_delete{
    background-color: #f2e6e6 !important;
    color: black !important;
}
/* .list .item div{

    display: inline-block;
}  */
.list .item .id{

background-color: rgb(92 92 139);
    color: white;
    text-align: center;
    border-radius: 50px;
    font-size: 14px;
    width: 39px;
    height: 20px;
    float: right;
 
}
.list .item .info_parent {
    width: 70%;
    display: inline-block;
    float: right;
}

.list .item .info_parent .info{

    font-size: 15px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
}

.description_page{

    background-color: rgb(234, 235, 241);
    display: block;
    color: #212121;
    border-radius: 6px;
    margin-bottom: 30px;
    font-size: 15px;
    padding: 20px;
    line-height: 2;
}

.list .item .info_parent .info .name_parent ,
 .list .item .info_parent .info .category_parent
 ,.list .item .info_parent .info .category_child
  ,.list .item .info_parent .info .number_parent
  ,.list .item .info_parent .info .price_parent
    ,.list .item .info_parent .info .product_colors_parent
 {
    margin-bottom: 20px;
    
}
.list .item .info_parent .info .name_parent,
.list .item .info_parent .info .category_parent,
.list .item .info_parent .info .category_child
{
    display: block;

}
.list .item .info_parent .info .number_parent,
.list .item .info_parent .info .price_parent
{
display: inline-block;
margin-left: 20px;
}

.list .item .info_parent .info .colors{
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
}
.list .item .info_parent .info .colors .item_color{
  background-color: rgb(255, 255, 255);
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 2px 7px;
  border-radius: 70px;
  cursor: pointer;
  border: 2px solid rgb(245, 245, 245);
  transition: 0.4s;
}
.list .item .info_parent .info .colors .selected{
    border:2px solid rgb(40 97 143);
    transition: 0.4s;
}
.list .item .info_parent .info .colors .color{
    width: 10px;
    height: 10px;
    border-radius: 80px;
    margin-left: 10px;
    display: inline-block;
    border: 1px solid rgb(240, 240, 240);
}
.list .item .info_parent .info .colors .number {
   display: inline-block;
   font-size: 13px;
   color: #3e3f40;
   margin-right: 20px;
}

.list .item .info_parent .info .colors .size{
    display: inline-block;
    margin-right: 15px;
    font-size: 13px;
    color: rgb(76, 76, 76);
}
.list .item .info_parent .image_parent{

    width: 100px;
    height: auto;
    margin-right: 10px;
    display: inline-block;
    float: right;
}

.list .item .info_parent .discount_parent{
    background: #d83737;
    color: white;
    width: 140px;
    text-align: center;
    border-radius: 30px;
}
.list .item .info_parent .discount_parent span {
    font-size: 13px !important;
}
.list .item .info_parent .discount_parent .price {
    font-size: 14px !important;
    font-weight: 700;
}
.list .item .info_parent .general_discount{
    background: #ffe7e7;
    color: rgb(0, 0, 0);
    width: 130px;
    text-align: center;
    border-radius: 30px;
}
.list .item .info_parent .general_discount span{
    font-size: 13px !important;
}
.list .item .info_parent .general_discount .price{
  font-size: 14px !important;
  font-weight: 700;
}
.list .item .info_parent .image_parent img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
}

.list .item .info_parent .info label {

    color: #6c6c6c;
    font-size: 14px;
}
/* .list .item .info_parent .info span {

    font-size: 14px;
} */

.list .item .category_parent{
    display: block;
}
.list .item .category_child {
    display: block;
}
.list .item .category_child .child , .list .item .category_parent .parent , .list .item .name_parent .name{
    background-color: #f9f9f9;
    margin-left: 7px;
    padding: 5px 5px;
    border-radius: 6px;
    color: black;
    margin-bottom: 5px;
    display: inline-block;
    text-align: justify;
    line-height: 1.7;
}

.list .item .btn_parent{
    width: 30%;
    display: inline-block;
    box-sizing: border-box;
    padding-right: 10px;
    text-align: left;
}
.list .item button{
    margin-left: 10px;
    margin-bottom: 10px;
}
.list .item .btn_fire{
    background: #fafaff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 6px;
    font-size: 13px;
    width: 70px;
    height: 30px;
    text-align: center;
    vertical-align: top;
    transition: 0.3s;
}
.list .item .fire{
    background-color: #ffedd1;
    transition: 0.3s;
}
.list .item .btn_fire img{
    width: 20px;
    position: absolute;
    margin-top: -10px;
    margin-right: -10px;
}

.list .item .btn_update{
    background: #fafaff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 6px;
    font-size: 13px;
    width: 70px;
    height: 30px;
    text-align: center; 
}
.list .item .btn_delete {
    background: #fbf0f0;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 6px;
    font-size: 13px;
    width: 70px;
    height: 30px;
}
.list .item .btn_no_verify{
    background: #f2c9c9;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 6px;
    font-size: 12px;
    width: 88px;
    height: 30px;
}
.list .item .btn_verify {
    background: #d7e8db;
    color: #000000;
    border: none;
    border-radius: 6px;
    font-size: 12px;
    width: 88px;
    height: 30px;
}

.list .item .btn_gallery{
    background: #fafaff;
    color: black;
    border: none;
    border-radius: 6px;
    font-size: 13px;
    width: 70px;
    height: 30px;
}

.list .item .btn_discount {
   background: #fafaff;
   color: black;
   border: none;
   border-radius: 6px;
   font-size: 13px;
   width: 70px;
   height: 30px;
}

.list .empty_container p{
    background: rgb(236 236 236);
    color: #434343;
    font-size: 15px;
    width: fit-content;
    padding: 11px;
    border-radius: 10px;
    font-weight: bold;
}

/* create form style */
.form_page{
    padding-top: 100px;
    padding-bottom: 100px;
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
}

.form_page .form_inner h1{
    font-weight: 700;
    font-size: 16px;
}
.form_page .input_parent{
    margin-top: 20px;
}
.form_page .number_product_parent{
    padding-top: 30px;
}
.des_input{
 color: rgb(78, 78, 78);   
 font-size: 12px;
 margin-top: 5px;
 padding-right: 20px;
}
.form_page .number_input_disabled{
    opacity: 0.4;
}
.form_page .labels_parent label{
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  color: #272b39;
}
.form_page .labels_parent span {
  display: inline-block;
  cursor: pointer;
}
.form_page .checkbox_input{
    display: none;
}
.form_page .comment_user{
     background: #f8f8f8;
     padding: 10px;
     box-sizing: border-box;
     border-radius: 10px;
     border: 1px solid #e0e0e0;
     margin-top: 10px;
     margin-bottom: 10px;
}
.form_page .admin_comment{
    background-color: rgb(243 243 243) !important;
    border: 2px solid #545353 !important;
    color: rgb(17, 17, 17) !important;
    border: 4px solid #ffffff !important;
}

.form_page .comment_user .date_label{
   color: #3e3e3e;
   font-size: 13px;
}
.form_page .comment_user .date{
    color: #292929;
    font-size: 14px;
}

.form_page .reply_comments .comment_user{
    background: #f6f6f6;
    padding: 10px;
    border-radius: 10px;
    color: #050505;
    border: 1px solid #e0e0e0;
}
.form_page .price_value{
    width: fit-content;
    font-size: 16px;
    padding: 8px 20px;
    color: black;
}
.form_page label{
    font-size: 14px;
    color: #222222;
}
.form_page .ckeditor{
    margin-top: 10px;
}
.form_page input , .form_page textarea{
    width: 100%;
    display: block;
    padding: 7px 20px 7px 20px;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    color: black;
    margin-top: 10px;
}
.form_page textarea{
    resize: vertical;
    max-height: 150px;
    min-height: 100px;
}
.form_page select {
    width: 100%;
    display: block;
    padding: 7px 20px 7px 20px;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    color: black;
    margin-top: 10px;
}
.form_page .image_parent{
    margin-top: 20px;
    width: 200px;
    position: relative;
}

.form_page .image_product_uploaded{

    width: 200px;
    height: 200px;
    border-radius: 6px;
    object-fit: contain;
    object-position: center;
}

.form_page .image_product_uploading {

    width: 200px;
    height: 200px;
    opacity: 0.5;
    border-radius: 6px;
    background-color: white;
    object-fit: contain;
    object-position: center;
}

.form_page .image_edit_uploaded_parent{

    width: 200px;
    height: 200px;
    margin-top: 10px;
}
.form_page .image_edit_uploaded_parent img{
    width: 200px;
    height: 200px;
    object-fit: contain;
    object-position: center;
    border-radius: 6px;
}
.form_page .delete_image_btn{
    position: absolute;
    top: 173px;
    right: 3px;
}
.form_page .delete_image_btn button{
    background: rgb(215 65 65);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 10px;
    padding: 2px 5px;
}
.form_page .progress_upload_image_parent{
    background-color: rgb(255, 255, 255);
    width: 90%;
    height: 18px;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);   
}
.form_page .progress_upload_image {

    background-color: rgb(84 151 121);
    border-radius: 3px;
    height: 18px;
    float: left;
    text-align: center;
    color: white;
    font-size: 12px;
    transition: 0.5s;
}
.form_page .success_upload_image_messgae{

    position: absolute;
    background: green;
    color: white;
    font-size: 13px;
    padding: 2px 6px 0px 6px;
    text-align: center;
    margin-top: 5px;
    margin-right: 5px;
    border-radius: 30px;
    transition: 0.5s;
}


.form_page .submit_form{
    background-color: #5a5985;
    color: white;
    border: none;
    border-radius: 9px;
    padding: 6px 20px 7px 20px;
    font-size: 14px;
    margin-top: 30px;
}
.form_page .add_attribute{
    color: white;
    background-color: #6fa51c;
    border: none;
    border-radius: 6px;
    padding: 5px 20px;
}
.form_page .parent_comment{
    margin-bottom: 20px;
}
.form_page .verify_comment_btn{
    background-color: #6bbb82;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 3px 5px 3px 5px;
    font-size: 12px;
    margin-top: 30px;
    margin-right: 20px;
}
.form_page .no_verify_comment_btn {
    background-color: #d85656;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 3px 5px 3px 5px;
    font-size: 12px;
    margin-top: 30px;
    margin-right: 20px;
}
.form_page .body_comment{
    text-align: justify;
    line-height: 1.7;
}

.form_page .error{
    color: #ff2d2d;
    font-size: 13px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 2px 20px 2px 20px;
    margin-top: 5px;
    margin-bottom: 16px;
    font-weight: bold;
    display: block;
}
.file_upload_parent .retry_upload_image button{
    position: absolute;
    background-color: #3d3d67;
    z-index: 2;
    color: white;
    font-size: 13px;
    border: none;
    border-radius: 20px;
    width: 80px;
    text-align: center;
    padding: 3px 0px 3px 0px;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}
.file_upload_parent .input_file_parent{
  margin-top: 10px;
}
.file_upload_parent .input_image_upload{
    display: none;
}
.file_upload_parent .image_select{

    width: 100%;
    display: block;
    padding: 7px 20px 7px 20px;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    color: rgb(104, 104, 104);
    cursor: pointer;
    background-color: white;
}

.form_page .parent_number{
    margin-top: 30px;
    margin-bottom: 30px;
    border-top: 3px solid #e6e6e6;
    border-bottom: 3px solid #e6e6e6;
    padding-top: 20px;
    padding-bottom: 20px;
}
.alert_empty_category {
    background: rgb(58, 58, 87);
    border-radius: 10px;
    padding: 20px;
    color: white;
    font-size: 15px;
    text-align: center;
    width: 450px;
    margin: 0 auto;
    box-sizing: border-box;
}
.alert_empty_category span{
    display: block;
    padding-bottom: 20px;
}
.alert_empty_category button {

    background-color: #45b97b;
    color: white;
    border-radius: 10px;
    padding: 3px 10px 3px 10px;
    border: none;
}
@media (max-width:700px) {
    .alert_empty_category {
            width: 95%;
            font-size: 14px;
        }
}

/* gallery style page */

.gallery_parent{
    padding-top: 100px;
    padding-bottom: 100px;
    width: 90%;
    margin: 0 auto;
}

.gallery_parent .image_gallery_parent{

    position: relative;
    display: inline-block;
}
.gallery_parent .image_parent {
    width: 150px;
    position: relative;
    margin-left: 6px;
}
.gallery_parent .parent_old_gallery{
    display: inline;
}
.gallery_parent .image_product_uploading {
    width: 150px;
    height: 150px;
    opacity: 0.5;
    border-radius: 6px;
    background-color: white;
    object-fit: cover;
    object-position: center;
    margin-left: 5px;
}
.gallery_parent .image_product_uploaded{
    width: 150px;
    height: 150px;
    border-radius: 6px;
    object-fit: cover;
    object-position: center;
    margin-left: 5px;
}
.gallery_parent .delete_image_btn {
    position: absolute;
    top: 125px;
    right: 3px;
}

.gallery_parent .delete_image_btn .delete_icon_image {
    background: rgb(0 0 0 / 38%);
    color: white;
    border-radius: 2px;
    width: 16px;
    height: 15px;
    padding: 4px 3px;
    cursor: pointer;
}
.gallery_parent .success_upload_image_messgae {
    position: absolute;
}
.gallery_parent .success_upload_image_messgae .icon_success_image_upload{
    width: 14px;
    height: 14px;
    padding: 3px;
    background: #313176;
    border-radius: 23px;
    margin-top: 2px;
    margin-right: 3px;
}
.gallery_parent .progress_upload_image_parent {
    background-color: rgb(255, 255, 255);
    width: 90%;
    height: 5px;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.gallery_parent .progress_upload_image {

    background-color: #587c85;
    border-radius: 3px;
    height: 5px;
    float: left;
    text-align: center;
    color: white;
    font-size: 12px;
    transition: 0.5s;
}
.gallery_parent .input_file_parent {

    margin-top: 30px;
    margin-bottom: 50px;
    display: inline-block;
}
.gallery_parent .input_file_parent .btn_upload_image_gallery{
    cursor: pointer;
    color: black;
    border-radius: 10px;
    font-size: 14px;
    border: 1px solid #787878;
    padding: 20px 9px;
    border-style: dashed;
    border-width: 2px;
    margin-left: 20px;
}
.gallery_parent .input_file{
    display: none;
}
.gallery_parent .error{
    color: white;
    font-size: 13px;
    font-weight: 700;
    color: red;
}

.gallery_parent .retry_upload_image button {
    position: absolute;
    background-color: #4e4e4e;
    z-index: 2;
    color: white;
    font-size: 13px;
    border: none;
    border-radius: 20px;
    width: 80px;
    text-align: center;
    padding: 3px 0px 3px 0px;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}
.gallery_parent .btn_submit_parent button{
    background: rgb(86 86 130);
    color: white;
    border: none;
    border-radius: 6px;
    padding: 9px 15px;
    margin-top: 30px;
}
.gallery_parent .empty_gallery_product{
    font-size: 14px;
    display: inline-block;
    margin-top: 30px;
    color: #686868;
    position:absolute;
}

/* delivery style */
.form_page .line_segment{
    width: 100%;
    height: 4px;
    margin-top: 30px;
    border-radius: 40px;
    background-color: rgb(226, 226, 226);
}
.form_page .parent_inputs_delivery_active{
    opacity: 1;
}
.form_page .parent_inputs_delivery_inactive {
    background-color: white;
    opacity: 0.5;
}
.form_page .submit_form_inactive{
    background-color: #a3a3b3;
    color: white;
    border: none;
    border-radius: 9px;
    padding: 6px 20px 7px 20px;
    font-size: 14px;
    margin-top: 30px;
    cursor: unset !important;
}

.form_page .checkbox_parent{
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-right: 5px;
    border-radius: 5px;
}
.form_page .checkbox_parent label {
   cursor: pointer;
}
.form_page .checkbox_parent div {
  display: inline-block;
}
.form_page .checkbox_parent .checkbox_input{
    display: none;
}
.form_page .checkbox_parent span {
    margin-right: 10px;
    cursor: pointer;
    position: absolute;
    margin-top: -12px;
}
.checked {

    display: block;
    width: 40px;
    height: 14px;
    border-radius: 16px;
    background-color: rgb(121, 136, 155);
    border: 1px solid rgb(121, 136, 155);
    position: relative;
    margin-right: 15px;
    vertical-align: sub;
}

.checked::after {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    left: 1px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: rgb(255, 255, 255);
    animation: checkedAnimation ease 0.5s;
    animation-fill-mode: forwards;

}

@keyframes checkedAnimation {
    0% {
        left: 25px;

    }

    100% {
        left: 1px;

    }
}

.checked_disabled {
    display: block;
    position: relative;
    width: 40px;
    height: 14px;
    border-radius: 16px;
    background-color: rgb(209, 209, 209);
    border: 1px solid rgb(207, 207, 207);
    margin-right: 15px;
    vertical-align: sub;
}

.checked_disabled::before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    left: 25px;
    box-sizing: border-box;
    border-radius: 100px;

    background-color: rgb(255, 255, 255);
    animation: checked_disableAnimation ease 0.5s;
    animation-fill-mode: forwards;
}

@keyframes checked_disableAnimation {
    0% {
        left: 1px;
    }

    100% {
        left: 25px;
    }
}
.rmdp-container{
    display: block !important;
}
.rmdp-input{
    height: 40px !important;
}

/* discount product style */

.list_parent .btn_discount_datail{
   width: 100px;
   height: 30px;
   border: none;
   border-radius: 6px;
   background: #e8c79c;
}
.form_page .product_discount_parent{
    margin-top: 20px;
}
.form_page .product_discount_parent label{
  font-size: 16px;
  color: #646464;
}
.list .info .admin_mark{
    background-color: #424070;
    color: white !important;
    font-size: 13px !important;
    border-radius: 4px;
    text-align: center;
    padding: 1px 4px;
}
.list .info .user_mark {
    background-color: #fafafa;
    color: rgb(63, 63, 63) !important;
    font-size: 13px !important;
    border-radius: 4px;
    text-align: center;
    padding: 1px 4px;
}

.form .admin_mark {
    background-color: #424070;
    color: white !important;
    font-size: 13px !important;
    border-radius: 4px;
    text-align: center;
    padding: 1px 4px;
    width: 70px;
    margin-bottom: 20px;
}

.form .user_mark {
    background-color: #fafafa;
    color: rgb(63, 63, 63) !important;
    font-size: 13px !important;
    border-radius: 4px;
    text-align: center;
    padding: 1px 4px;
    width: 70px;
    margin-bottom: 20px;
}
.form .btn_view_reply_comment{
    background: #5e7290;
    border: none;
    border-radius: 6px;
    font-size: 12px;
    margin-right: 20px;
    padding: 3px 10px;
    color: white;
}

/* attribute page style */

.parent_input_attribute{
   background-color: #ffffff00;
}
.parent_input_attribute .key{
    width: 30%;
    background: white;
    color: black;
    border: 1px solid #b9b9b9;
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    margin-left: 20px;
    display: inline-block;
}
.parent_input_attribute .value {
    width: 50%;
    background: white;
    color: black;
    border: 1px solid #b9b9b9;
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    margin-left: 20px;
    display: inline-block;
}
.parent_input_attribute .add_attribute{
    background-color: #5eda66;
    color: white;
    border: none;
    border-radius: 6px;
    display: inline-block;
    font-size: 23px;
    text-align: center;
    width: 35px;
    height: 35px;
    position: absolute;
    margin-top: 11px;
}
.parent_input_attribute .submit_attribute{
    background-color: #48476b;
     color: white;
     border: none;
     border-radius: 6px;
     text-align: center;
     width: 100px;
     height: 40px;
     display: block;
     margin-top: 30px;
}
.item_attribute{
    background-color: rgb(243, 245, 248);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.item_attribute .delete_attribute{
    background: #ffffff00;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    color: #e87979;
}
.item_attribute .key{
    padding-right: 20px;
    font-size: 14px;
    display: inline-block;
    font-size: 14px;
}
.item_attribute .value {
    padding: 20px;
    font-size: 14px;
    display: inline-block;
    font-size: 14px;
}
.item_attribute span {
  font-size: 13px;
  color: #5e5e5e;
}
 .form_page .product_name_parent .name_parent {
     background-color: #ffffff;
     margin-bottom: 30px;
     padding: 10px;
     border-radius: 10px;
 }
  .form_page .product_name_parent .name_parent .title{
     color: rgb(99, 99, 99);
     font-size: 14px;
  }
  .form_page .product_name_parent .name_parent .name {
      color: rgb(73, 72, 72);
      font-weight: 700;
      font-size: 16px;
  }
  .form_page .edit_attribute_btn{
    background: #d2bfcd00;
    border: none;
    color: #555555;
    border-radius: 4px;
    font-size: 13px;
    padding: 2px 20px;
    display: block;
  }

  .form_page .cancel_edit_attribute{
    background: #f3f3f3;
    color: #3d3d3d;
    border: none;
    padding: 3px 10px;
    border-radius: 6px;
    font-size: 13px;
  }
  .form_page .cancel_edit_attribute .close_icon{
   margin-left: 10px;
   color: red;
  }
  .form_page .parent_input_attribute .edit_attribute{
     background: #e4e8e9;
     border: none;
     color: #282828;
     border-radius: 6px;
     text-align: center;
     width: 80px;
     height: 40px;
  }
@media only screen and (max-width: 900px) {
.parent_input_attribute .key {
        width: 100%;
        margin-left: 0px;
        display: block;
    }

    .parent_input_attribute .value {
        width: 100%;
        margin-left: 0px;
        display: block;
    }

    .parent_input_attribute .add_attribute {
        display: inline-block;
        position: relative;
        margin-top: 20px;
        width: 50px;
        height: 40px;
        font-size: 20px;
        padding-bottom: 3px;
    }

     .parent_input_attribute .submit_attribute {
 
         display: inline-block;
         margin-top: 30px;
         margin-right: 20px;

     }
}

/* style slider page */
.list_slides{
    width: 90%;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
}
.list_slides .row{
    width: 100%;
    height: auto;
    background-color: #ebecee;
    border-radius: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    padding: 20px;
    color: #212121;
    font-size: 14px;
}
.list_slides .row .id{
    background-color: #585e76;
    color: white;
    padding: 2px 10px;
    border-radius: 10px;
    margin-left: 20px;
}
.list_slides button{
    width: 100px;
    height: 28px;
    text-align: center;
    border: none;
    border-radius: 6px;
    color: white;
    background-color: #ac6a7d;
    float: left; 
}

.input_url_slide{
    width: 100%;
    display: block;
}
.input_url_slide label{
    display: block;
    width: 100%;
    font-size: 14px;
    color: #3a3a3a;
    margin-bottom: 10px;
    margin-top: 10px;
}
.input_url_slide input{
    width: 100%;
    box-sizing: border-box;
    padding: 6px 10px 6px 10px;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    font-size: 14px;
    direction: ltr;
}
.gallery_parent .slide{
  width: 100%;
  display: block;
}
.gallery_parent .slide_parent{
    width: 100%;
    display: block;
    background-color: rgb(244 245 247);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid rgb(255, 255, 255);
    margin-bottom: 20px;
}
@media only screen and (max-width: 400px) {
 
    .list_slides button {

            display: block;
            float: unset;
            margin-top: 20px;
        }
}

/* colors style */
.list_parent .info_parent .color_parent{
    display: inline-block;
}
.list_parent .info_parent .color_parent .color{
    width: 30px;
    height: 30px;
    border-radius: 60px;
    border: 1px solid rgb(226, 226, 226);
}
.form_page .color_picker{
    margin-top: 10px;
}


/* colors product style */

.product_colors{
    margin: 0 auto;
    width: 100%;
}
.product_colors .parent_colors{
    background-color:rgba(0, 0, 0, 0.59);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10;
}
.product_colors .parent_colors .list{
    display: flex;
    flex-direction: column;
    width: 350px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    height:auto;
    max-height: 80%;
    box-sizing: border-box;
}
.parent_colors .types_size_visible .type button{
    width: 100%;
    text-align: right;
    background: unset;
    border: none;
    padding: 20px;
    border-bottom: 1px solid #dedede;
    font-size: 15px;
    font-weight: 700;
    box-sizing: border-box;
}
.product_colors .parent_colors .types_size_visible{
    display: block;
}
.product_colors .parent_colors .types_size_invisible{
    display: none;
}
 .product_colors .list .close{
    width: 100%;
    background-color: rgb(241, 242, 247);
    text-align: right;
    box-sizing: border-box;
    border-bottom: 2px solid white;
    border-radius: 10px 10px 0px 0px;
}
.product_colors .list .back_to_types{
   width: 100%;
   box-sizing: border-box;
   padding: 10px;
   text-align: left;
}
 .product_colors .list .back_to_types .back{
     background-color: unset;
     border: none;
     color: black;
     box-sizing: border-box;
     font-size: 14px;
     padding: 4px 20px;
 }
 .product_colors .list .close button{
    font-size: 18px;
    border: none;
    color: rgb(0, 0, 0);
    background: unset;
    width: 40px;
    height: 40px;
    margin-top: 6px;
 }
.product_colors .list .colors{
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    background-color: rgb(241, 242, 247);
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
}
.product_colors .list .colors .item{
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid rgb(255, 255, 255);
    width: 100%;
    background-color: unset;
    box-sizing: border-box;
}
.product_colors .color_product_parent{
 display: inline-block;
}
.product_colors .color_product{
    width: 30px;
    height: 30px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 50px;
    border: 2px solid #f0f0f0;
}
.product_colors .attribute{
    width: 400px;
    display: flex;
}
.product_colors .size {
    display: block;
    color: #454545;
    font-size: 15px;
}
.product_colors .size span{
   display: inline-block;
}
.product_colors .size .amount{
   padding-right: 10px;
}

.product_colors .list .colors .color{
    width: 30px;
    height: 30px;
    border-radius: 60px;
    border: 2px solid rgb(255, 255, 255);
}
.product_colors .list .colors .name {
    font-size: 14px;
    font-weight: 700;
    padding-right: 20px;
    box-sizing: border-box;
}
.product_colors .list .colors .add_parent{
    flex: auto;
}
.product_colors .list .colors .add_parent .add{
    width: 70px;
    height: 30px;
    font-size: 13px;
    background-color: rgb(96 109 138);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    float: left;
}
.product_colors .list .colors .add_parent .added {
    background-color: rgb(201 83 83);
    color: #ffffff;
}
.alert_empty_colors{
    text-align: center;
}
.alert_empty_colors span{
    display: block;
    text-align: center;
    font-size: 15px;
    box-sizing: border-box;
    padding: 5px;
}
.alert_empty_colors button {
    display: block;
    margin: 0 auto;
    background: green;
    border: none;
    color: white;
    border-radius: 6px;
    padding: 3px 10px 3px 10px;
    font-size: 13px;
    margin-top: 10px;
}
 /* product colors */
.product_colors .content{
    display: flex;
    flex-flow: wrap;
    margin-top: 20px;
}
.product_colors .content .item{

    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px 10px 5px 20px;
    margin-left: 10px;
    margin-bottom: 10px;
}
.product_colors .content .item .color{
    width: 30px;
    height: 30px;
    border-radius: 60px;
    border: 2px solid rgb(255, 255, 255);
}
.product_colors .content .item .name {
  width: 150px;
  padding-top: 4px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 700;
  box-sizing: border-box;
}
.product_colors .content .item .delete {
    color: gray;
    margin-left: 20px;
    border: none;
    background: unset;
}
.product_colors .show_colors button{
    border: 2px dashed #c5c7da;
    border-radius: 6px;
    background: unset;
    width: 100px;
    height: 40px;
    margin-left: 10px;
}
.product_colors .create_color button{
    width: 145px;
    border: none;
    background: #656789;
    color: white;
    height: 35px;
    border-radius: 6px;
    margin-top: 30px;
}

.product_colors .price , .product_colors .number{

    margin-right: 20px;
}

.product_colors .price input ,
.product_colors .number input {
  width: 150px;
   margin-right: 10px;
   margin-top: 0px;
   border: 1px solid #d4d4d4;
   font-size: 15px;
   padding: 5px;
   border-radius: 6px;
   background: unset;
   display: inline-block;
}
.product_colors .error_validation{
    color: red;
    font-size: 14px;
}


/* users style */
.user_item{
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
}
.user_item .info{
    display: inline-block;
}
.user_item .info > div{
    display: inline-block;
    margin-left: 20px;
}
.user_item .title{
    color: gray;
    font-size: 13px;
    font-weight: 700;
}
.user_item .date{
    width: 175px;
}
.user_item .mobile_parent .mobile{
    font-size: 17px;
    font-weight: 700;
    margin-right: 20px;
    display: inline-block;
    width: 100px;
}
.payment_state{
    font-size: 14px;
    font-weight: 700;
    display: block !important;
    margin-left: 0px !important;
    margin-bottom: 20px;
}
.payment_state .ok{
    color: green;
}
.payment_state .no{
    color: red;
}
.user_item .btns{
    margin-top: 20px;
}
.user_item .btns .orders_btn{
   background-color: #f5f5f9;
}
.user_item .btns>button{
    display: inline-block;
    border: none;
    font-weight: 700;
    width: 100px;
    border-radius: 6px;
    padding: 4px 0px;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-left: 10px;
}
.user_item .btns .ok{
    background: #eefff4;
    color: rgb(7, 7, 7);
}
.user_item .btns .no {
    background: #fff0f3;
    color: #262626;
}
.user_item .btns .detail_btn{
    background: #f1f6ff;
    color: #2d2d2d;
}
.user_item .address_info_parent{
   display: block !important;
   margin-top: 20px;
}
.user_item .address_info_parent .address{
   font-size: 15px;
   font-weight: 700;
}
.name_parent{
    margin-top: 10px;
}
.user_item .name_parent .name{
    display: inline-block;
}
.user_item .name_parent .name>div{
    font-size: 15px;
    margin-right: 5px;
    display: inline-block;
    font-weight: 700;
}
.user_item .price {
    font-weight: 700;
    font-size: 16px;
    margin-right: 5px;
}
.user_item .toman{
   font-weight: 700;
   font-size: 13px;
   margin-right: 5px;
}


/* detail order */
.detail_order_parent {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    right: 0px;
    z-index: 30;
    background-color: #838383ad;
}

.detail_order_parent .modal {
    position: absolute;
    background-color: #ffffff;
    width: 60%;
    max-width: 800px;
    height: 80%;
    max-height: 800px;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    box-sizing: border-box;
    border-radius: 10px;

}

.detail_order_parent .modal .state_order {
    padding: 10px 20px;
}

.detail_order_parent .modal .state_order .no_payment {
    font-size: 15px;
    color: red;
    font-weight: 700;
}

.detail_order_parent .modal .state_order .ok_payment {
    font-size: 15px;
    font-weight: 700;
    color: rgb(65, 181, 65);
}

.detail_order_parent .detail_order {
    height: 90%;
    overflow-y: scroll;
    padding: 10px 20px;
    box-sizing: border-box;
}

.detail_order_parent .navigation {
    background-color: #ffffff;
    border-top: 1px solid #d0d0d0;
    width: 100%;
    position: absolute;
    bottom: 8px;
}

.detail_order_parent .navigation .payment {
    background: #f24141;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 7px 22px;
    font-size: 14px;
    font-weight: 700;
}

.detail_order_parent .navigation .btns {
    float: left;
    margin-left: 20px;
    margin-top: 11px;
}

.detail_order_parent .navigation .close {
    background: #ffffff;
    color: rgb(126 126 126);
    border: none;
    border-radius: 6px;
    padding: 7px 22px;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid #c7c7c7;
    margin-left: 20px;
}

.detail_order .address_parent {
    border-bottom: 1px solid rgb(206, 206, 206);
}

.detail_order .address_parent>div {
    margin-bottom: 10px;
}

.detail_order .address_parent .address {
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.detail_order .address_parent .title {
    font-size: 13px;
    color: #6f6f6f;
    font-weight: 700;
    margin-left: 10px;
}

.detail_order .address_parent .first_name {
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
}

.detail_order .address_parent .last_name {
    display: inline-block;
    margin-right: 5px;
    font-weight: 700;
    font-size: 14px;
}

.detail_order .address_parent .delivery_price {
    font-size: 16px;
    font-weight: 700;
    color: #373737;
}

.detail_order .address_parent .total_price {
    font-size: 17px;
    font-weight: 700;
    color: #373737;
}

.detail_order .address_parent .toman {
    font-size: 12px;
    color: black;
    font-weight: 700;
    margin-right: 5px;
}

.detail_order .address_parent .city_parent>div {
    display: inline-block;
}

.detail_order .address_parent .city_parent .province {
    margin-left: 30px;
}

.detail_order .address_parent .price_parent>div {
    display: inline-block;
}

.detail_order .address_parent .delivery_price_parent {
    margin-left: 30px;
}

.detail_order .address_parent .mobile,
.address_parent .phone {
    font-weight: 700;
    color: #444444;
}

.detail_order .address_parent .mobile_parent>div {
    display: inline-block;
}

.detail_order .address_parent .mobile {
    margin-left: 30px;
}

.detail_order .image img {
    width: 130px;
    height: 130px;
    object-fit: contain;
}

/* .detail_order .products_parent{
    padding-top: 20px;
} */
.detail_order .products_parent .item {
    border-bottom: 1px solid rgb(203, 203, 203);
    box-sizing: border-box;
    padding: 20px;
}

.detail_order .products_parent .image {
    width: 130px;
    height: 130px;
    display: inline-block;
}

.detail_order .products_parent .image img {
    width: 130px;
    height: 130px;
    object-fit: contain;
}

.detail_order .products_parent .info {
    margin-top: 20px;
    margin-right: 20px;
    vertical-align: top;
}

.detail_order .products_parent .info>div {
    margin-bottom: 10px;
}

.detail_order .products_parent .name {
    font-weight: 700;
    font-size: 15px;
}

.detail_order .products_parent .first_price_parent>div {
    display: inline-block;
}

.detail_order .products_parent .first_price_parent .first_price {
    color: gray;
    font-size: 15px;
    margin-left: 20px;
}

.detail_order .products_parent .first_price_parent .percent {
    background-color: rgb(255, 255, 255);
    color: rgb(230, 62, 62);
    border-radius: 6px;
    font-size: 14px;
    padding: 0px 6px;
    text-align: center;
    border: 1px solid red;
}

.detail_order .products_parent .percent_title {
    margin-right: 10px;
    font-size: 13px;
}

.detail_order .products_parent .final_price {
    color: black;
    font-size: 18px;
    font-weight: 700;
    display: inline-block;
    margin-top: 20px;
}

.detail_order .products_parent .final_price_parent .toman {
    margin-right: 5px;
    color: black;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
}

.detail_order .products_parent .color_parent>div {
    display: inline-block;

}

.detail_order .products_parent .color_parent {
    display: inline-block;
}

.detail_order .products_parent .color_parent .color {
    width: 20px;
    height: 20px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 100px;
}

.detail_order .products_parent .color_parent .color_name {
    font-size: 13px;
    margin-right: 10px;
    vertical-align: top;
    color: rgb(71, 71, 71);
}

.detail_order .products_parent .number_parent>div {
    display: inline-block;
}

.detail_order .products_parent .number_parent .title {
    font-size: 14px;
    color: gray;
}

.detail_order .products_parent .number {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    margin-right: 10px;
}

.detail_order .products_parent .size_parent {
    background: #f6f7f9;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    padding: 0px 10px;
    border-radius: 10px;
}

.detail_order .products_parent .size_parent>div {
    display: inline-block;
}

.detail_order .products_parent .size_parent .title {
    color: #3d3c3c;
    font-size: 13px;
    vertical-align: top;
}

.detail_order .products_parent .size_parent .size {
    color: black;
    font-size: 15px;
    font-weight: 700;
    margin-right: 10px;
}
/* style login register */
.login_register_parent {
    float: left;
    margin-left: 143px;
    margin-top: 28px;
}

.login_register_parent .login_register_btn {
    background: #ffffff;
    font-size: 14px;
    padding: 5px 8px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #d4d4d4;
}

/* login register */

.parent_login {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: block;
    z-index: 70;
    right: 0px;
    top: 0px;
    background-color: #ffffff85;
}

.parent_login .parent {

    background-color: #fff;
    border-radius: 9px;
    height: 261px;
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 20px;
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 500px;
    box-shadow: 0px 0px 10px #f3f3f3;
    z-index: 7;
}

.parent .login-title {
    margin-top: -31px;
}

.parent .login-title,
.login-info {

    font-size: 15px;
    color: black;
    text-align: center;
    padding-top: 20px;
}

.parent .login-input-text {
    margin-top: 30px;
}

.parent .login-input-text input {

    display: block;
    margin: 0 auto;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #b55d79;
    font-size: 17px;
    letter-spacing: 2px;
    padding-left: 10px;
    width: 50%;
    height: 40px;
    direction: ltr !important;
    text-align: center;
    outline: none;


}


.parent_login .parent .footer_login {
    display: block;
    width: 100%;
}

.parent_login .parent .timer {
    display: block;
    width: 100%;

    text-align: center;
    font-size: 15px;
    color: #5c5c5c;
    padding: 12px 10px 0px 10px;
    box-sizing: border-box;
}

.parent_login .parent .login_btn .btn {

    margin: 0 auto;
    display: block;
    cursor: pointer;
    margin-top: 30px;
    background-color: #b55d79;
    color: white;
    border: none;
    border-radius: 7px;
    font-size: 15px;
    width: 50%;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;

}

.parent_login .parent .login_btn .btn:hover {

    background-color: #ad4c6b;
}

.parent .resend_otp {
    text-align: center;
    padding-top: 20px;

}

.parent .resend_otp a {
    color: rgb(35, 55, 98);
}

.parent_login .parent_error {
    position: absolute;
    display: block;
    right: 40;

}

.parent_login .parent_error span {

    background-color: rgb(181, 67, 67);
    color: white;
    display: block;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 6px;
    padding: 4px 10px 4px 10px;
    font-size: 14px;

}

.login_close span {
    padding: 10px 20px 10px 20px;
    position: absolute;
    font-size: 28px;
    color: #adadad;
    top: 0;
    cursor: pointer;
}


/* /// load spinner */

.inner_logout_modal .lds-dual-ring {
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: -98px;
    margin-top: 0px;
}

.advertising .btn_container .lds-dual-ring {
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: -5px;
    margin-right: 0px;
}

.lds-dual-ring {
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: -44px;
    margin-top: -5px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid rgb(255, 255, 255);
    border-color: rgb(235, 235, 235) transparent rgb(235, 235, 235) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* logout style */

.logout_modal {
    background-color: #0000006b;
    position: fixed;
    width: 100%;
    height: 100vh;
    display: block;
    z-index: 40;
    top: 0;
    right: 0;
}

.logout_modal .inner_logout_modal {

    background-color: white;
    z-index: 9;
    width: 500px;
    height: 200px;
    margin: 0 auto;
    border-radius: 6px;
    top: 35%;
    position: relative;
}

.logout_modal .inner_logout_modal .logout_close_modal span {
    position: absolute;
    padding: 6px 26px;
    font-size: 25px;
    color: rgb(190, 190, 190);
    cursor: pointer;
    z-index: 1;
}

.logout_modal .inner_logout_modal .title {
    width: 100%;
    margin-top: 20px;
    position: absolute;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.logout_modal .inner_logout_modal .text {
    width: 100%;
    margin-top: 75px;
    position: absolute;
    text-align: center;
    font-size: 14px;

}

.logout_modal .inner_logout_modal .btns {
    width: 100%;
    display: block;
    margin-top: 140px;
    position: absolute;
}

.logout_modal .inner_logout_modal .cansel {
    display: inline-block;
    width: 50%;

}

.logout_modal .inner_logout_modal .ok {
    display: inline-block;
    width: 50%;

}

.logout_modal .inner_logout_modal .cansel div {
    width: 60%;
    background-color: #eaeaea;
    color: #000000;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 60px;
}

.logout_modal .inner_logout_modal .ok div {
    background-color: rgb(151, 74, 69);
    color: white;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    border-radius: 60px;
}

/* account style */
.menu_sidebar_app .profile .admin_image{
    width: 70px;
}
.menu_sidebar_app .profile .admin_image img{
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 100px;
}
.menu_sidebar_app .profile .admin_image{
    display: inline-block;
        width: 60px;
        height: 60px;
}
.menu_sidebar_app .profile .admin_name {
display: inline-block;
    vertical-align: top;
    margin-top: 23px;
    margin-right: 8px;
    font-size: 13px;
    color: #3c3c3c;
}


/* profile style */

.form_page .profile .name_input input{
    background-color: white;
    border-radius: 10px;
    border: 1px solid gainsboro;
    padding: 5px 10px;
    box-sizing: border-box;
    width: 100%;
    font-size: 15px;
}
.form_page .profile .btns .submit{

    width: 150px;
    border: none;
    color: white;
    background-color: #345195;
    border-radius: 6px;
    padding:7px 0px;
    font-size: 14px;
    font-weight: 700;
}

.form_page .profile .content{
    padding: 5px 20px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.form_page .profile .info .mobile_parent{
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
}
.form_page .profile .info .name_parent {
    font-size: 16px;
    display: block;
    font-weight: 700;
    margin-bottom: 10px;
}
.form_page .profile .title {
    font-size: 13px;
    display: inline-block;
    color: gray;
    font-weight: 700;
    margin-left: 10px;
}
.form_page .profile .file_upload{
    margin-bottom: 20px;
}
.form_page .profile .avatar{
    display: inline-block;
    width: 150px;
}
.form_page .profile .avatar img{
    width: 150px;
    object-fit: contain;
}


/* property style */

.properties{

}
.properties .create_property_btn{
display: inline-block;
    margin-right: 20px;
    padding: 6px 10px;
    border-radius: 8px;
    border: unset;
    background: #52c252;
    color: white;
    font-weight: 700;
    font-size: 13px;
}
.properties .category_name{

    display: inline-block;
    font-weight: 700;
}
.properties .content{
    margin-top: 30px;
}
.properties .item{
    padding: 20px;
    border: 1px solid #d7d7d7;
    font-size: 15px;
    background-color: white;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 10px;
}
.properties .item .btn_item{
    border: none;
    border-radius: 6px;
    background-color: #fafaff;
    padding: 3px 10px;
    margin-left: 10px;
    float: left;
    font-weight: 700;
    color: #1c1c1c;
}
.properties .values{
    padding-top: 30px;
    padding-right: 30px;
}
.properties .values .item_value{
   padding: 9px 0px;
}
.properties .add_value_btn{
    border: none;
    color: green;
    padding: 7px 10px;
    border-radius: 7px;
    font-weight: 700;
    margin-bottom: 10px;
    background-color: #fafaff;
}
.properties .edit_parent{
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.405);
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10;
}
.properties .edit_parent .edit{
    background-color: white;
    width: 500px;
    height: 200px;
    top: 50%;
    right: 50%;
    transform: translate(50% , -50%);
    position:absolute;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
}
.properties .edit_parent .edit input{
    width: 100%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 8px;
    padding: 8px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 700;
}

.properties .edit_parent .edit_btns{
    position: absolute;
    bottom: 15px;
    left: 20px;
}

.properties .edit_parent .edit .update_btn {
    padding: 7px 18px;
    border: none;
    background: #706b89;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    color: white;
}
.properties .edit_parent .edit .close_btn {
    padding: 7px 18px;
    border: none;
    background: #ba5050;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    color: white;
    margin-left: 20px;
}
.properties .title{
    margin-bottom: 20px;
    display: inline-block;
}
.properties .property_name {
    display: inline-block;
    margin-right: 5px;
}
.properties .create_properties_parent{

    background-color: #0000006a;
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    z-index: 10;
}
.properties .create_properties_parent .create{
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    max-height: 80%;
    height: auto;
    position:absolute;
    top: 50%;
    right: 50%;
    transform: translate(50% , -50%);
    overflow-y: auto;
}

.properties .create_properties_parent .create input{
    background-color: white;
    border: 1px solid rgb(191, 191, 191);
    border-radius: 8px;
    width: 100%;
    padding: 8px 10px 8px 60px;
    font-size: 14px;
    color: black;
    font-weight: 700;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.properties .create_properties_parent .create .delete{
    position: absolute;
        left: 26px;
        margin-top: 7px;
}
.properties .create_properties_parent .create .add_property{
    margin-top: 20px;
    margin-bottom: 20px;
}
.properties .create_properties_parent .create .add_property button{
    background-color: rgb(244 244 244);
        color: green;
        border: none;
        border-radius: 8px;
        padding: 8px 20px;
        font-weight: 700;
}
.properties .create_properties_parent .create .add_property img {
    width: 10px;
}
.properties .create_properties_parent .create .btns{
  float: left;
}
.properties .create_properties_parent .create .btns .submit{
    padding: 7px 33px;
    border: none;
    border-radius: 8px;
    font-weight: 700;
    background: #64be62;
    color: white;
}
.properties .create_properties_parent .create .btns .close {
    padding: 7px 20px;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 8px;
    font-weight: 700;
    background: #ffffff;
    color: rgb(49, 49, 49);
    margin-left: 20px;
}
.properties .create_properties_parent .create .delete{
    position: absolute;
        left: 26px;
        margin-top: 10px;
        border: none;
        border-radius: 100px;
        font-size: 10px;
        padding: 3px 6px 1px 6px;
        color: black;
}
.properties .multiple{
   display: inline-block;
   float: left;
   margin-left: 30px;
   font-size: 13px;
}
.properties .multiple label{
    cursor: pointer;
}
.properties .multiple > div{
    display: inline-block;
    cursor: pointer;
}
.properties .multiple .check{
    vertical-align: middle;
    margin-left: 10px;  
}
/* property product style */

.properties_product .multiple_select_able{
  background-color: rgb(255, 255, 255);
  padding: 5px 20px;
  font-size: 13px;
  font-weight: 700;
  float: left;
  color: rgb(26, 26, 26);
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgb(243, 243, 243);
}
.properties_product .item_property_parent{
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid rgb(223, 223, 223);
}
.properties_product .property_name{
    display: inline-block;
    font-weight: 700;
    font-size: 15px;
}
.properties_product .values_btn_show{
    float: left;
    border: none;
    background-color: #f0f1f5;
    vertical-align: sub;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 8px;
    font-size: 13px;
    margin-right: 20px;
}
.properties_product .custom_value_btn{
    border: none;
    background-color: #e6ecf2;
    color: rgb(40, 40, 40);
    vertical-align: sub;
    display: inline-block;
    padding: 5px 8px;
    border-radius: 8px;
    font-size: 13px;
    margin-right: 30px;
    vertical-align: super;
    font-size: 14px;
}
.properties_product .custom{
    padding-right: 10px;
    font-size: 15px;
    font-weight: 700;
}
.properties_product .values{
    background: #f2f3f3;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    display: none;
}
.properties_product .visisble{
    display: block;
}
.properties_product .values .value_item{
   
    margin-bottom: 20px;
}
.properties_product .values .value_item .label_parent {
    display: inline-block;
    vertical-align: super;
    margin-right: 10px;
    padding: 6px;
}
.properties_product .values .value_item .check_box{
    display: inline-block;
}
.properties_product .values .value_item .check_box .input{
    display: none;
}

.properties_product .values .value_item .check_box .uncheck{
    display: block;
    width: 16px;
    height: 16px;
    background-color: whitesmoke;
    border: 2px solid #b5b5b5;
    border-radius: 100px;
}
.properties_product .values .value_item .check_box .check{
    display: block;
    width: 16px;
    height: 16px;
    background-color: rgb(100 104 121);
    border-radius: 100px;
    border: 2px solid silver;
}
.properties_product .category_name{
  background-color: #545b6f;
  font-weight: 700;
  font-size: 15px;
  color: white;
  padding: 10px 20px;
}

.properties_product .empty_property{
    font-size: 14px;
    color: rgb(46, 46, 46);
    padding: 20px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(209, 209, 209);
}
.properties_product .submit_parent button{
    background: #4e5a7a;
    border: none;
    padding: 10px 35px;
    border-radius: 6px;
    color: white;
    margin-top: 30px;
    font-weight: 700;
}
.properties_product .empty_values{
    font-size: 14px;
}
.properties_product .product_name{
    font-weight: 700;
    font-size: 14px;
    padding: 20px;
    background: #ebecef;
    border-radius: 10px;
    margin-bottom: 10px;
}
.properties_product .custom_value_parent{
   background-color: #0000006c;
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0px;
   right: 0px;
   z-index: 10;
}
.properties_product .custom_value{

    background-color: white;
    width: 400px;
    height: auto;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50% , -50%);
    box-sizing: border-box;
    padding: 25px;
}
.properties_product .custom_value .description{
  font-size: 14px;
  line-height: 2;
  display: block;
  text-align: justify;
}
.properties_product .custom_value .input{
   padding: 5px 10px;
   border: 1px solid rgb(237, 237, 237);
   border-radius: 6px;
   background-color: rgb(244, 244, 244);
   font-size: 15px;
   display: block;
   width: 100%;
   box-sizing: border-box;
   margin-top: 20px;
}
.properties_product .custom_value .submit{
   background-color: rgb(62, 62, 131);
   color: white;
   border-radius: 6px;
   border: none;
   padding: 7px 30px;
   font-size: 14px;
   float: left;
   margin-top: 20px;
}
.properties_product .custom_value .close {
    background-color: rgb(255, 255, 255);
    color: rgb(50, 50, 50);
    border-radius: 6px;
    border: 1px solid rgb(240, 240, 240);
    padding: 7px 15px;
    font-size: 14px;
    float: left;
    margin-top: 20px;
    margin-left: 30px;

}
/* category brands style */

.category_brands_parent .item{
    width: fit-content;
    background-color: rgb(242 243 248);
    border-radius: 70px;
    padding: 5px 20px 5px 5px;
    margin-bottom: 10px;
    margin-left: 10px;
    display: inline-block;
    font-size: 15px;
}
.category_brands_parent .item .name {
    display: inline-block;
    font-size: 15px;
}
.category_brands_parent .item .cancel{
    display: inline-block;
    background-color: unset;
    border: unset;
    margin-right: 20px;
    color: rgb(181, 181, 181);
    font-size: 12px;
}
.brands_list_parent{
    background-color: #00000067;
    width: 100%;
    height: 100vh;
    position:fixed;
    top: 0px;
    right: 0px;
    z-index: 10;
}
.brands_list_parent .brands_list{
   width: 400px;
   height: 60%;
   background-color: white;
   border-radius: 10px;
   position:absolute;
   top: 50%;
   right: 50%;
   transform: translate(50% , -50%);
   box-sizing: border-box;
   padding-left: 4px;
   padding-top: 4px;
   padding-bottom: 10px;
   overflow: hidden;
}
.brands_list_parent .brands_list .brands_item{
    overflow: auto;
    height: 100%;
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    padding: 20px;
    bottom: 0px;
    padding-bottom: 70px;
}
.brands_list_parent .brands_list .item{
    padding: 13px 0px;
    box-sizing: border-box;
}
.brands_list_parent .brands_list .item .name{
    display: inline-block;
    font-size: 15px;
}
.brands_list_parent .brands_list .item .added,
.brands_list_parent .brands_list .item .add {
    display: inline-block;
    float: left;
    width: 56px;
    text-align: center;
    border: none;
    padding: 3px 0;
    font-size: 13px;
    border-radius: 10px;
}
.brands_list_parent .brands_list .item .add{
    background-color: #d7ffd7;
    color: black;
}
.brands_list_parent .brands_list .item .added {
    background-color: rgb(255 239 239);
    color: rgb(0, 0, 0);
}
.brands_list_parent .brands_list .btns{
    text-align: left;
        border-top: 1px solid rgb(0 0 0 / 22%);
        position: absolute;
        bottom: 0px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 0px 0px 10px 10px;
        position: fixed;
        margin-bottom: 0px;
        bottom: 0px;
}
.category_brands_parent .submit{
    background-color: rgb(154, 62, 108);
    color: white;
    border-radius: 8px;
    border: none;
    padding: 8px 20px;
    display: block;
    margin-top: 40px;
}
.brands_list_parent .brands_list .btns .close {
    background-color: rgb(255, 255, 255);
    color: rgb(44, 44, 44);
    border-radius: 8px;
    border: 1px solid rgb(231, 231, 231);
    padding: 8px 20px;
    margin-left: 20px;
}
.category_brands_parent .select_brands button{
    background: #e2f2e2;
    border: none;
    padding: 8px 20px;
    border-radius: 16px;
    font-size: 14px;
    color: #181818;
    margin-bottom: 40px;
    margin-top: 20px;
    font-weight: 700;
}
.category_brands_parent .empty_category_brands{
   font-size: 14px;
   color: gray;
}
.category_brands_parent .select_brands{
    color: rgb(55, 55, 55);
    font-size: 14px;
}
.create_type_size .btn_create_type_size{
    background-color: rgb(232, 235, 244);
    border-radius: 50px;
    border: none;
    padding: 10px 20px;
    color: black;
    font-weight: 700;
}
.list_size_types .parent_size{
    background-color: rgb(255, 255, 255);
    margin-top: 30px;
    border-radius: 10px;
    border: 1px solid rgb(231, 231, 231);
}
.list_size_types .select_type{
    display: block;
    margin-top: 10px;
    width: 100%;
    padding: 10px 5px;
    border-radius: 6px;
    border: none;
    font-size: 15px;
    background: #f8f8f8;
}
.list_size_types .empty_sizes{
  font-size: 14px;
  margin-right: 20px;
}
.list_size_types .parent_size .item_size{
    padding: 20px;
}
.list_size_types .parent_size .item_size .btn_parent{
    float: left;
}
.size_name_parent{
    display: inline-block; 
    margin-left: 40px;
}
.list_size_types .create_size_types{
    background-color: rgb(51, 174, 78);
    border-radius: 50px;
    border: none;
    padding: 10px 20px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin-bottom: 30px;
}
.list_size_types .edit_btn{
    margin-left: 10px;
    margin-bottom: 10px;
    background: #f0f1f4;
    border: none;
    border-radius: 8px;
    color: black;
    padding: 5px 14px;
}
.list_size_types .title, .list_size_types .size{
   display: inline-block;
   margin-left: 5px
}
.list_size_types .parent_modal{

    background-color: #0000006c;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10;
}
.list_size_types .modal{
   position:absolute;
   background-color: white;
   box-sizing: border-box;
   border-radius: 10px;
   padding: 20px;
   width: 400px;
   height: auto;
   right: 50%;
   top: 50%;
   transform: translate(50% , -50%);
}
.list_size_types .close{
  background-color: white;
  border: 1px solid rgb(213, 213, 213);
  border-radius: 6px;
  color: black;
  padding: 7px 10px;
  float: left;
  font-size: 14px;
  
}
.list_size_types .submit{
    background-color: rgb(96, 99, 125);
    border: none;
    border-radius: 6px;
    color: rgb(255, 255, 255);
    padding: 7px 40px;
    float: left;
    font-size: 14px;
    margin-right: 20px;
}
.list_size_types label {
    display: inline-block;
    font-size: 14px;
}
.list_size_types input{
  width: 100%;  
  box-sizing: border-box;
  border: none;
  background-color: rgb(244, 244, 244);
  padding: 5px;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  margin-top: 10px;
  font-size: 15px;
}
.list_size_types .input_parent{
   margin-bottom: 20px;
}
.list_size_types .info{
    display: inline-block;
}
.list_size_types .size_btns{
    display: inline-block;
    float: left;
}
.empty_size_types{
    background-color: rgb(238, 241, 245);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}
.empty_size_types span{
    font-size: 15px;
}
.empty_size_types button{

    background-color: rgb(136, 47, 60);
    color: white;
    border: none;
    padding: 5px 20px;
    border-radius: 6px;
    margin-right: 20px;
    
}


.menu_icon {
    display: none;
    vertical-align: middle;
    margin-left: 30px;
}
.menu_icon img{
    width: 25px;
    vertical-align: middle;
}
.menu_icon button{
   border: none;
   background-color: unset;
}
.close_menu{
   display: none;
    padding-right: 30px;
    margin-bottom: 20px;
}
.close_menu button{
    background-color: #d8d9df;
    border: none;
    width: 46px;
    height: 40px;
    border-radius: 14px;
}  
.close_menu button img{
    width: 24px;
    vertical-align: middle;
}
.background_menu{
   background-color: rgba(0, 0, 0, 0.384);
   position: fixed;
   display: none;
   width: 100%;
   height: 100vh;
   z-index: 10;
}
.background_menu_hide{
   display: none;
}

@media only screen and (max-width: 1200px) {
    .header {
        width: 75%;
        height: auto;
    }
    .main_app {
        width: 75%;
    }
    .menu_sidebar_app {
        width: 25%;
    }
    .header .inner_header {
        width: 95%;
    }
    .list_parent {
        width: 95%;
        padding-top: 120px;
    }
    .list_parent .header .title {
        font-size: 14px;
        display: block;
        margin-bottom: 20px;
    }
    .list_parent .header .search_parent {
        margin-right: 0px;
    }
    .list_parent .search_parent input {
        width: 300px;
    }
    .list_parent .header .create_parent {
        float: unset;
        margin-left: 0px;
        margin-right: 40px;
    }
    .list_parent .header .create_parent .create {
        padding: 10px 13px 11px 12px;
        font-size: 13px;
    }
}

@media only screen and (max-width: 960px) {
    .menu_sidebar_app {
        background: rgb(241 241 241);
        width: 50%;
        display: block;
    }
    .menu_sidebar_app_hide{
       display: none;
    }
    .header {
        width: 100%;
    }
    .main_app {
        width: 100%;
    }
    .menu_icon {
        display: inline-block;
    }
    .close_menu{
        display: block;
     }
     .background_menu{
        display: block;
     }
     .list .item .info_parent {
        width: 100%;
    }
    .list .item .btn_parent {
        width: 100%;
        text-align: right;
        margin-top: 30px;
        padding-right: 0px;
    }
    .list .item .info_parent .info .colors {
        margin-right: 0px;
    }
    .list_size_types .size_btns {
        display: block;
        margin-top: 26px;
        float: unset;
    }
    .list_size_types .edit_btn {
        padding: 5px 10px;
        font-size: 12px;
    }
    .detail_order_parent .modal {
        width: 85%;
    }
    .list_parent .header .create_parent {
        margin-right: 0px;
        display: block;
        margin-top: 20px;
    }
    .list {
        padding-top: 100px;
    }
    .list_parent .search_parent input {
        width: 100% !important;
    }
    
}
@media only screen and (max-width: 500px) {
    .menu_sidebar_app {
        width: 100%;
    }
    .list_size_types .modal {
        width: 90%;
    }
    .user_item .mobile_parent .mobile {
        font-size: 15px;
    }
    .user_item .info > div {
        margin-left: 0px;
    }
    .detail_order_parent .modal {
        width: 95%;
    }
}
@media only screen and (max-width: 350px) {
    .user_item .btns>button {
        margin-bottom: 20px;
        width: 100%;
    }
    .user_item .btns {
        float: unset;
        margin-top: 20px;
    }
}